import http from "@/helpers/http";
import { qsPack,pack } from "@/helpers/utils";
import qs from "qs";

const service = "faUnidrinkCategory/";

export function getCategoryList(type) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/category/index",
    method: "get",
    params: {
      _: Date.now(),
      sort: "weight",
      order: "asc",
      type,
    },
  });
}

export function addCategory(form) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/category/add",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qsPack(form),
    params: {
      dialog: 1,
    },
  });
}

export function editCategory(form) {
  return http.request({
    url: `/SYTdknLasJ.php/unidrink/category/edit/ids/${form.id}`,
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qsPack(form),
    params: {
      dialog: 1,
    },
  });
}

export function deleteCategory(id) {
  return http.request({
    url: "SYTdknLasJ.php/unidrink/category/del",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      action: "del",
      ids: id,
    }),
  });
}

export function dragCategory(ids, changeid) {
  return http.request({
    url: "/SYTdknLasJ.php/ajax/weigh",
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify({
      ids,
      changeid,
      field: "weigh",
      orderway: "desc",
      table: "unidrink_category",
      pk: "id",
    }),
  });
}

export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}

export function save(form) {
	return http.request({
		url: service + "save",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function updateById(form) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function drag(ids) {
	return http.request({
		url: service + "drag",
		method: "POST",
		params: {
			ids: ids
		}
	})
}


export async function listAll(form) {
  form.currPage = 1;
  form.pageSize = 100;
  let flag = true;//{code:"0000",message:"查询成功"}
  let listResult = {list:[]};
  while(flag){
    let res = await listByPage(form);
    let { code, result, message } = res;
    if (code !== "0000") {
      if(form.currPage<=1){
        return res;
      }else{
        return {code:"0000",message:"查询成功",result:listResult};
      }
    }
    const { list, total } = result;
    listResult.total = total;
    listResult.list.push(...list);
    if(total>form.currPage*form.pageSize){
      flag = true;
    }else{
      flag = false;
    }
    form.currPage++;
  } 
  return {code:"0000",message:"查询成功",result:listResult};
}