<template>
  <div class="panel-page_container">
    <div class="panel-page_header">
      <el-button type="info" class="fa fa-refresh" @click="getList"></el-button>
      <el-button class="" type="primary" @click="addHandle">添加</el-button>
    </div>
    <div class="panel-page_main">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-background="transparent"
        @row-dblclick="editHandle"
        row-key="id"
      >
        <el-table-column
          prop="id"
          label="ID"
          min-width="40"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="type_text"
          label="类型"
          min-width="60"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="120">
          <template v-slot="scope">
            <span v-html="scope.row.name"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="shop_text"
          label="门店"
          min-width="120"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="flag_text"
          label="标志"
          min-width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="image"
          label="图片"
          min-width="40"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <el-image
              v-if="scope.row.image"
              style="width: 30px; height: 30px"
              :src="scope.row.image.split(',')[0]"
              :preview-src-list="scope.row.image.split(',')"
              preview-teleported
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="weigh"
          label="权重"
          min-width="60"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          min-width="40"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <span
              class="fa fa-circle"
              :style="{ color: USER_STATUS[scope.row.status].color }"
              >{{ " " + USER_STATUS[scope.row.status].label }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="center" align="center">
          <template v-slot="scope">
            <el-button
              class="icon-btn move-handle fa fa-arrows"
              @click="editHandle(scope.row)"
            >
              <!-- 编辑 -->
            </el-button>
            <el-button
              class="icon-btn fa fa-pencil"
              type="primary"
              @click="editHandle(scope.row)"
            >
              <!-- 编辑 -->
            </el-button>
            <el-button
              class="icon-btn fa fa-trash"
              type="danger"
              @click="delHandle(scope.row)"
            >
              <!-- 删除 -->
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { USER_STATUS } from "@/helpers/constants";
import { msgErr, msgSuc } from "@/helpers/message";
import {
  getCategoryList,
  deleteCategory,
  dragCategory,
} from "@/apis/unidrink/category";
import Sortable from "sortablejs";
const calls = [];
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      USER_STATUS: USER_STATUS,
      tableData: [],
      loading: false,
      initForm: {},
      form: {},
      paginationData: {},
    };
  },
  emits: ["add", "edit"],
  created() {
    console.log(calls);
  },
  mounted() {},
  methods: {
    editHandle(row) {
      this.$emit("edit", row);
    },
    getList() {
      this.loading = true;
      getCategoryList(this.type)
        .then(({ total, rows }) => {
          this.tableData = rows;
          this.total = total;

          const tbody = document.querySelector(".el-table__body-wrapper tbody");
          Sortable.create(tbody, {
            handle: ".move-handle",
            onEnd: ({ newIndex, oldIndex }) => {
              if (newIndex === oldIndex) {
                return;
              }
              const dragItem = this.tableData.splice(oldIndex, 1)[0];
              // console.log(dragItem, this.tableData);
              this.tableData.splice(newIndex, 0, dragItem);
              // console.log(dragItem, this.tableData);

              console.log("Sortable onEnd", newIndex, oldIndex);
              dragCategory(
                this.tableData.map((item) => item.id).join(","),
                this.tableData[newIndex].id
              )
                .then(({ code, msg }) => {
                  if (code === 1) {
                    msgSuc("操作成功！");
                  } else {
                    msgErr(msg);
                  }
                })
                .finally(() => {
                  this.getList();
                });
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addHandle() {
      this.$emit("add");
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteCategory(row.id).then(({ code, msg }) => {
          if (code === 1) {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-page_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .panel-page_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
.move-handle {
  background-color: #2c3e50;
  color: #ffffff;
}
</style>
