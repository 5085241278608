<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="类型：" prop="type">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option
                v-for="(item, index) in categoryNoAllOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="父ID：" prop="pid">
            <el-select v-model="form.pid" placeholder="请选择">
              <el-option
                v-for="(item, index) in categoryOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="标志：" prop="flag">
            <el-select v-model="form.flag" placeholder="请选择" multiple>
              <el-option
                v-for="(item, index) in CATEGORY_TAG_OPTIONS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片：" prop="image">
            <uploads v-model="form.image"></uploads>
          </el-form-item>
          <el-form-item label="关键字：" prop="keywords">
            <el-input v-model="form.keywords"></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="description">
            <el-input v-model="form.description" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="门店：" prop="shop_id">
            <el-select v-model="form.shop_id" placeholder="请选择" multiple>
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：">
            <el-radio-group v-model="form.status">
              <el-radio
                v-for="(item, index) in USER_STATUS_OPTIONS"
                :key="index"
                :label="item.value"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ADVERT_LINK_TYPE_OPTIONS,
  USER_STATUS_OPTIONS,
  CATEGORY_OPTIONS,
  CATEGORY_TAG_OPTIONS,
} from "@/helpers/constants";

import {
  getCategoryList,
  addCategory,
  editCategory,
} from "@/apis/unidrink/category";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getShopList } from "@/apis/auth";
import { reactive } from "vue";
const categoryNoAllOptions = reactive(
  CATEGORY_OPTIONS.filter((item) => item.value != "all")
);
</script>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      initForm: {
        status: "normal",
        type: "product",
        pid: 0,
        shop_id: [],
      },
      form: {},
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        shop_id: [{ required: true, message: "门店不能为空", trigger: "blur" }],
      },
      shopOptions: [],
      categoryOptions: [],
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.getShopOptions();
        this.getCategoryOptions();
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.shop_id = this.form.shop_id
          .split(",")
          .map((item) => Number(item));
        this.form.flag = this.form.flag ? this.form.flag.split(",") : [];
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        console.log(this.form);

        const promise = this.mode === "edit" ? editCategory : addCategory;
        promise(this.form).then(({ code, msg }) => {
          if (code === 1) {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(msg);
          }
        });
      });
    },

    getShopOptions() {
      const page = 1;
      const limit = 10;
      this.shopOptions = [{ name: "全部", id: 0 }];
      const fn = (page, limit) => {
        getShopList(page, limit).then(({ rows, total }) => {
          this.shopOptions.push(...rows);
          if (page * limit < total) {
            fn(page + 1, limit);
          }
        });
      };
      fn(page, limit);
    },

    getCategoryOptions() {
      this.categoryOptions = [{ name: "无", id: 0 }];
      getCategoryList("all").then(({ rows, total }) => {
        this.categoryOptions.push(...rows);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
